.h-featured {
  text-align: center;
  font-weight: 300;
  color: #4d5959;
  padding: 30px 0;
}

.featured-collection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 40px;
  justify-content: space-between;
}
@media only screen and (max-width: 600px) {
  .h-featured {
    text-align: center;
    font-weight: 300;
    margin-left: 10px;
    color: #4d5959;
    padding: 30px 0;
  }
}
