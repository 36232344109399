.top-account-section {
  background: #757575;
  padding: 50px;
  color: white;
  font-size: 21px;
}

.account-container {
  display: flex;
  flex-direction: row;
  padding: 50px;
}

.account-sidebar {
  width: 20%;
  height: 170px;
  border: 1px solid lightgray;
}

.account-sidebar-item {
  text-align: start;
  padding: 10px;
  padding-left: 20px;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  cursor: pointer;
}

.account-sidebar-item:hover {
  background: #f5f7f9;
  color: #b59677;
}

.account-sidebar .active {
  background: rgba(136, 136, 136, 0.12);
}

.account-main-container {
  width: 100%;
  padding: 50px;
  padding-top: 0;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.hello-para {
  text-align: start;
  font-size: 14px;
}

.hello-para span {
  font-weight: bold;
  padding: 4px;
}

.hello-para a:hover {
  color: #b59677;
  text-decoration: underline;
}

.bold-order {
  text-align: start;
  font-weight: bold;
  font-size: 15px;
}

.order-section {
  border: 2px solid green;
  padding: 8px 30px;
  color: green;
  display: flex;
  gap: 20px;
  justify-content: start;
  align-items: center;
}

.order-section p {
  margin-bottom: 0px;
}

.order-section b {
  text-decoration: underline;
}

.order-section b:hover {
  color: #b59677;
  cursor: pointer;
}

.info-div {
  border: 1px solid lightgray;
  font-size: 14px;
  border-bottom: none;
}

.title {
  width: 30%;
  text-align: start;
  padding: 8px;
  font-weight: bold;
  border-bottom: 1px solid lightgray;
}

.value {
  text-align: start;
  padding: 8px;
  width: 70%;
  border-left: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.info-section {
  display: flex;
}
.address-btn {
  background: #b59677;
  color: white;
  font-weight: 500;
  padding: 8px 18px;
  border: none;
}
.address-main-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.default {
  font-weight: bold;
}
.thin-text {
  font-weight: 400;
  font-size: 13px;
}
.action-container {
  display: flex;
  gap: 10px;
}
.edit-btn {
  border: 1px solid black;
  padding: 6px 19px;
}
.edit-btn:hover {
  background: #b59677;
  border: 1px solid #b59677;
  color: white;
  cursor: pointer;
}
.add-address-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.edit-address-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.inputs-container {
  display: flex;
  gap: 15px;
}
.address-heading {
  font-size: 19px;
  padding-top: 30px;
}
.input-box label {
  text-align: start;
}
.checkboc {
  height: 14px;
  margin-right: 6px;
}
.edit-btn-new {
  background: white;
  outline: none;
  border: 1px solid black;
  width: 30%;
  padding: 8px 0;
}
.edit-btn-new:hover {
  background: #b59677;
  color: white;
  border: 1px solid #b59677;
}
.input-box {
  width: 100%;
}
.inputs-container {
  width: 100%;
}
.input {
  color: grey;
  font-size: 15px;
}
.input:focus {
  border: 1px solid black;
}
@media only screen and (max-width: 400px) {
  .account-container {
    flex-direction: column;
    gap: 1rem;
    padding: 10px;
  }
  .account-sidebar {
    width: 100%;
  }
  .account-main-container {
    padding: 0;
  }
  .input-box {
    width: auto;
  }
  .inputs-container {
    flex-direction: column;
  }
  .edit-btn-new {
    width: 48%;
  }
}
