.cart-diaries-section{
    display: flex;
    flex-direction: column;
    padding: 40px;
}
.cart-diaries-title{
    font-size: 30px;
    font-weight: 200;
    padding: 40px;
}
.diaries-container-top{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.cart-diary-first{
    display: flex;
    width: 20%;
    flex-direction: column;
    gap: 1rem;
}
.cart-diary-third{
    display: flex;
    flex-direction: column;
    width: 20%;
    gap: 1rem;
}
.cart-diary-first-item{

    height: 300px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}
.cart-diary-second-item{
    height: 300px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}
.cart-diary-third-item{
    height: 300px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}
.cart-diary-four-item{
    height: 300px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}
.cart-diary-second{
    height: 700px;
    width: 60%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.diaries-container-bottom{
    display: flex;flex-direction: row;
}
.diaries-bottom-first-item{
    height: 400px;
    width: 25%;
    background-repeat: no-repeat;
    background-size: contain;
}
.diaries-bottom-second-item{
    height: 400px;
    width: 25%;
    background-repeat: no-repeat;
    background-size: contain;
}
.diaries-bottom-third-item{
    height: 400px;
    width: 25%;
    background-repeat: no-repeat;
    background-size: contain;
}
.diaries-bottom-four-item{
    height: 400px;
    width: 25%;
    background-repeat: no-repeat;
    background-size: contain;
}

@media only screen and (max-width: 600px){
    .cart-diary-second{
        background: url("../../assets/images/client-diary2.webp");
        height: 200px;
        width: 60%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    .cart-diary-first-item {
        background: url(http://localhost:3000/static/media/client-diary1.67b490f35d1676da34bc.webp);
        height: 165px;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .cart-diary-second-item {
        height: 165px;
    }
    .cart-diary-third-item {
        height: 165px;
    }
    .cart-diary-four-item{
        height: 165px;
    }
    .diaries-bottom-first-item{
        height: 100px;
    }
    .diaries-bottom-second-item{
        height: 100px;
    }
    .diaries-bottom-third-item{
        height: 100px;
    }
    .diaries-bottom-four-item{
        height: 100px;
    }
}