.h-product {
  font-weight: 200;
  text-align: center;
  padding: 20px 0px;
}
/* CSS for OurProducts component */

/* Tabs styling */
.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .h-product {
    font-weight: 200;
    text-align: center;
    padding: 20px 0px;
  }
}
.tab {
  cursor: pointer;
  background-color: white;
  border: none;
  color: gray;
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 16px;
  outline: none;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}
.tab:hover {
  background-color: white;
  border-color: cadetblue;
  border-radius: 50px;
  color: cadetblue;
}
.tab.active {
  background-color: cadetblue;
  border-color: cadetblue;
  border-radius: 50px;
  color: #fff;
}

/* Slider container styling */
.slider-container {
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 20px;
}

.slider {
  display: flex;
  transition: transform 0.5s ease;
}

.slider .zoom-card {
  flex: 0 0 auto;
  margin-right: 10px; /* Adjust as needed */
  width: 300px; /* Set a fixed width for each ZoomCard */
}
.slick-track .slick-slide {
  min-width: 430px !important;
}
