.zoom-card {
    width: 270px;
    overflow: hidden;
    margin: 20px;
    perspective: 1500px; /* Perspective for 3D effect */
}

.image-container {
    position: relative;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    height: 430px;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.zoomed-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
    transform: scale(1);
    object-fit: cover;
}
.original-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    backface-visibility: hidden;
}

.image-container.zoomed .zoomed-image {
    transform: scale(1.2);
}

.content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}


.feature-suit-title{
    font-weight: 500;
    text-align: start;
}
.discount-container{
    background: #F68E56;
    top: 10px;
    left: 10px;
    color: white;
    font-size: 10px;
    font-weight: bold;
    padding: 5px 15px;
    position: absolute;
}
.new-container{
    background: #109533;
    top: 45px;
    left: 10px;
    color: white;
    font-size: 10px;
    font-weight: bold;
    padding: 5px 15px;
    position: absolute;
}
.icons-zoom-container{
    background: white;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: bold;
    padding: 5px 6px;
    position: absolute;
}
.icons-zoom-img{
    height: 15px;
    cursor: pointer;
}
.icons-zoom-img:hover{

}
.hover-text{
    z-index: 1;
    top: 356px;
    width: 100%;
    color: white;
    font-size: 17px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 400;
    padding: 12px 0;
    position: absolute;
}
.quick-shop-btn{
    background: #b59677;
    border: none;
    outline: none;
    opacity: .8;
    color :#fff;
}
.quick-shop-btn:hover{
    background: #b59677;
    border: none;
    outline: none;
    opacity: 1;
    color :#fff;
}
.price-container{
    display: flex;
    gap: 10px;
    flex-direction: row;
}
.price{
    text-decoration: line-through;
    color: #878787;
}
.discounted-price{
    color: red;
}
.feature-img-container{
    display: flex;
    gap:10px;
    justify-content: start;
    align-items: start;
}
.img-round{
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    cursor: pointer;
}
.img-round:hover{
    border: 3px solid black;
}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    visibility: hidden;
    width: 120px;
    height: 30px;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 6px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    top: 10px;
    right: -30px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}