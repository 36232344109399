.wishlist-top-section {
  background: #757575;
  width: 100%;
  color: white;
  text-align: center;
  padding: 30px 0;
}
.wishlist-content {
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
@media only screen and (max-width: 600px) {
  .wishlist-content {
    padding: 0 30px;
  }
  .wishlist-top-section {
    margin-top: 80px;
  }
  .wishlist-btn {
    border: #b59677;
    background: #b59677;
    color: white;
    padding: 10px 53px !important;
  }
}
.wishlist-icon {
  font-size: 5rem;
}
.wishlist-title {
  font-size: 30px;
  color: #4d5959;
  font-weight: 400;
  line-height: 1.428;
  letter-spacing: 2px;
}
.wishlist-btn {
  border: #b59677;
  background: #b59677;
  color: white;
  padding: 10px 80px;
}
.ml-20 {
  margin-left: 20%;
}
.first-tab-wishlist-product {
  margin-left: 20%;
  width: 67%;
}
@media only screen and (max-width: 400px) {
  .first-tab-wishlist-product {
    margin-left: 0 !important;
  }
  .ml-20 {
    margin-left: 0;
  }
}
