.detail-modal-right .wishlist-title{
    text-align: start;
}

.first-tab-wish-card-content {
    display: flex;
    justify-content: center;
    flex-direction: column;

}
.first-tab-whish--price{
    text-align: start;
    display: flex;
    gap: 10px;
}
.first-tab-whish--price .from{
    text-decoration: line-through;
    font-weight: 300;
}
.first-tab-whish--price .to{
    font-weight: 400;
    color: red;
}
.first-tab-wish-descrition{
    text-align: start;
    font-weight: 400;
    font-size: 14px;
}
.wish-img-container{
    display: flex;
    gap: 10px;
}

.delete-container {
    cursor: pointer;
    top: 36px;
    left: 147px;
    color: black;
    background: white;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 10px;
    position: absolute;
}

.first-tab-wishlist-card {
    width: 100%;
    overflow: hidden;
    margin: 20px;
    perspective: 1500px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
     margin-left: 27%;
    gap: 3rem;
    border: 1px solid #eee;
}
@media only screen and (max-width: 600px){
    .first-tab-wishlist-card {
        width: 266px;
        overflow: hidden;
        margin: 20px;
        perspective: 1500px;
    }
}