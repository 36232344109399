.discunt-container{
    background: #F68E56;
    top: 10px;
   right: 158px;
    color: white;
    font-size: 10px;
    font-weight: bold;
    padding: 5px 15px;
    position: absolute;
}
.ne-containr{
    background: #109533;
    top: 45px;
    right: 158px;
    color: white;
    font-size: 10px;
    font-weight: bold;
    padding: 5px 15px;
    position: absolute;
}
.img-slaider{
    display: flex;
    flex-direction: column;
}
.img-slaider-top{
    display: flex;
    flex-direction: row;
}
@media only screen and (max-width: 600px) {
    .img-slaider{
        display: flex;
        flex-direction: row;
    }
    .img-slaider-top{
        display: flex;
        flex-direction: column-reverse;
        overflow: hidden;
    }
}