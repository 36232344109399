.collection-card {
  display: inline-block;
  position: relative;
  overflow: hidden;
  height: 400px;
  width: 48%;
  margin-bottom: 40px;
}

.collection-img {
  max-height: 300px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 40px;
  border-radius: 16px;
  transition: transform 0.5s;
}

.collection-card:hover .collection-img {
  transform: scale(1.1);
  border-radius: 16px;
}

.collection-img:hover {
  transform: scale(1.1);
}
.collection-title {
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 500;
  color: #222;
}
.collection-product {
  font: inherit;
  vertical-align: baseline;
  pointer-events: auto;
  margin-bottom: 40px;
}

@media only screen and (max-width: 600px) {
  .collection-card {
    display: inline-block;
    position: relative;
    overflow: hidden;
    height: 400px;
    width: 100%;
    margin-bottom: 40px;
  }
}
