.you-may-like-heading{
    color: #4d5959;
    font-weight: 300;
}
.you-may-like-heading:before{
    width: 100%;
    height: 1px;
    background: 0 0;
    border-top: 1px solid #ebebeb;
}
.you-may-like-heading:after{
    width: 100%;
    height: 1px;
    background: 0 0;
    border-top: 1px solid #ebebeb;
}
.slick-slide{
    width: 340px !important;
}