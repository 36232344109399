.terms-of-service-section{
    display: flex;
    flex-direction: column;
    padding: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}
.terms-heading{
    font-size: 38px;
    font-weight: 300;
    padding: 50px;
}

.terms-content{
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: start;
    gap: 10px;
}
.terms-title{
    font-weight: 500;
}
.terms-text{
    font-weight: 400;
    font-size: 15px;
}
@media only screen and (max-width: 600px){

    .terms-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: start;
        gap: 10px;
    }

    .terms-heading{
        font-size: 29px;
        font-weight: 300;
        padding: 10px 0;
    }

}