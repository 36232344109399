.calling-container {
  background: #f5f5f5;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px;
}
@media only screen and (max-width: 600px) {
  .calling-container {
    background: #f5f5f5;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px;
  }
  .calling-card {
    background: cadetblue;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
    color: #fff;
    border-radius: 16px;
  }
}
.calling-card {
  background: cadetblue;
  display: flex;
  width: 65%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  color: #fff;
  border-radius: 16px;
}
.calling-heaing {
  font-weight: bold;
  font-size: 20px;
}
.calling-border {
  height: 2px;
  margin: 20px auto;
  width: 80px;
  background: #fff;
}
