.first-tab-card {
  width: 100%;
  overflow: hidden;
  perspective: 1500px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  border: 1px solid #eee;
  border-radius: 1rem;
}

@media only screen and (max-width: 600px) {
  .first-tab-card {
    width: 266px;
    overflow: hidden;
    margin: 20px;
    perspective: 1500px;
  }
}
.first-image-container1 {
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  height: 230px;
  width: 70%;
  overflow: hidden;
  border-radius: 6px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.first-zoomed-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform: scale(1);
  object-fit: cover;
}
.first-original-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  backface-visibility: hidden;
}

.first-image-container1.zoomed .first-zoomed-image {
  transform: scale(1.2);
}

.content-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.suit-title {
  font-weight: bold;
}
.discount-container {
  background: #f68e56;
  top: 10px;
  left: 10px;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 15px;
  position: absolute;
}
.new-container {
  background: #109533;
  top: 45px;
  left: 10px;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 15px;
  position: absolute;
}
.icons-zoom-container1 {
  background: white;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-weight: bold;
  padding: 5px 6px;
  position: absolute;
}
.icons-zoom-img {
  height: 15px;
  cursor: pointer;
}
.icons-zoom-img:hover {
}
.four-hover-text1 {
  z-index: 1;
  top: 232px;
  width: 100%;
  color: white;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 400;
  padding: 12px 0;
  position: absolute;
}
.quick-shop-btn {
  background: #b59677;
  border: none;
  outline: none;
  opacity: 0.8;
  color: #fff;
}
.quick-shop-btn:hover {
  background: #b59677;
  border: none;
  outline: none;
  opacity: 1;
  color: #fff;
}
.price-container-card {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center !important;
  align-items: center !important;
}
.price {
  text-decoration: line-through;
  color: #878787;
}
.discounted-price {
  color: red;
}
.first-image-container1 {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.img-round {
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  cursor: pointer;
}
.img-round:hover {
  border: 3px solid black;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 120px;
  height: 30px;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 6px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  top: 10px;
  right: -30px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.firsst-ard-button-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  gap: 8px;
}
.quick-view-btn {
  color: cadetblue;
  border: 1px solid cadetblue;
  padding: 6px 30px;
  background: #fff;
  border-radius: 4px;
  font-weight: 500;
}
.read-more-btn {
  color: white;
  border: 1px solid cadetblue;
  background: cadetblue;
  padding: 6px 30px;
  border-radius: 4px;
  font-weight: 500;
}
.first-tab-card-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.discount-container-first {
  background: #f68e56;
  top: 36px;
  left: 37px;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 15px;
  position: absolute;
}
.new-container-first {
  background: #109533;
  top: 72px;
  left: 35px;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 15px;
  position: absolute;
}
.content-first {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.first-tab-price {
  color: lightgray;
}
.first-tab-descrition {
  font-weight: 200;
}
.hover-text1-first {
  z-index: 1;
  top: 155px;
  width: 100%;
  color: white;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 400;
  padding: 12px 0;
  position: absolute;
}

.read-detail-modal {
  display: flex;
}

.read-modal-left {
  width: 50%;
}
.read-modal-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.modal-right-title {
  font-weight: 500;
  font-size: 18px;
}
.pricing-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
}
.prices span {
  color: lightgray;
  text-decoration: line-through;
}
.prices p {
  color: red;
}
.instock {
  color: #6abac4;
}
.shipping-mdal-text {
  font-size: 13px;
  color: black;
  font-weight: 300;
}
.color-text {
  color: #3e3e3e;
  font-weight: bold;
  font-size: 19px;
}
