.reset-password-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 40px;
    justify-content: center;
    align-items: center;
}

.reset-passwoord-input-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 5px;
}

.reset-passwoord-input-container label {
    font-weight: 400;
    font-size: 14px;
}

.reset-password-input {
    outline: none;
    width: 420px;
    border: 1px solid lightgray;
    padding:8px 10px;
}
.reset-password-input:focus{
    border:1px solid black;
}
.reset-btn{
    padding: 10px 17px;
    background:white;
    font-weight:500;
    border:1px solid black;
}
.reset-btn:hover{
    color:white;
    background: #C4AB92;
    border:1px solid #C4AB92
}

@media only screen and (max-width: 500px){
    .reset-password-input {
        outline: none;
        width: 100% !important;
        border: 1px solid lightgray;
        padding:8px 10px;
    }
}