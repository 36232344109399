.heading-arrivals {
  text-align: center;
  font-weight: 400;
  padding: 20px 0;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  font: inherit;
  vertical-align: baseline;
}

.heading-arrivals:before {
  width: 100%;
  height: 1px;
  background: 0 0;
  border-top: 1px solid #ebebeb;
}

.heading-arrivals:after {
  width: 100%;
  height: 1px;
  background: 0 0;
  border-top: 1px solid #ebebeb;
}

.new-arrivals-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  padding: 20px 10px;
}

.show-more-btn {
  padding: 12px 50px;
  display: inline-block;
  background: 0 0;
  color: cadetblue;
  font-weight: 600;
  font-size: 14px;
  min-height: 40px;
  outline: none;
  margin: 20px 0;
  border: 1px solid cadetblue;
  transition: all 300ms;
}

.show-more-btn:hover {
  background: cadetblue;
  color: #fff;
  border-radius: 50px;
}
.collections-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media only screen and (max-width: 600px) {
}
