.arrow {
  height: 10px;
  width: 10px;
}

@media only screen and (max-width: 600px) {
  .navbar-c-mobile {
    top: 84px;
  }

  .navbar-c.scrolled {
    top: 72px !important;
    transform: translateY(-100%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
}

.navbar-c {
  display: flex;
  position: sticky;
  top: 0;
  color: #4d5959;
  background-color: #faebd7;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
  width: 100%;
}

.navbar-c-mobile {
  display: flex;
  position: fixed;
  color: #4d5959;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  top: 84px;
  z-index: 1;
  left: 0;
  width: 100%;
  background-color: white;
}

.navbar-c.hidden {
  transform: translateY(-100%);
}

.fa-bars {
  cursor: pointer;
}

.navbar-c-mobile.scrolled {
  top: 72px !important;
  transform: translateY(-100%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 200px;
  height: auto;
  padding-right: 30px;
}
@media only screen and (max-width: 400px) {
  .logo {
    padding-left: 30px;
    width: 200px;
    height: 100px;
    padding-right: 30px;
  }
}
.tabs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  width: 60%;
}

.tab-box {
  display: flex;
  color: black;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tab-box:hover {
  color: cadetblue;
}
.tab-box.active {
  background-color: cadetblue;
  color: #fff;
  padding: 4px 10px;
  border-radius: 20px;
}
.icons-container {
  display: flex;
  gap: 10px;
}

.icon {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.icon:hover {
  color: #b59677;
}

.icons-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: 0 5px;
}

.heart-icon {
  position: relative;
}

.wishlist-count-container {
  position: absolute;
  right: 47px;
  top: -7px;
  border-radius: 50%;
  background: black;
  color: white;
  font-size: 12px;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wishlist-count-container-mobile {
  position: absolute;
  left: 164px;
  top: 6px;
  border-radius: 50%;
  background: black;
  color: white;
  font-size: 12px;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-count-container {
  position: absolute;
  right: 0;
  top: -10px;
  border-radius: 50%;
  background: black;
  color: white;
  font-size: 12px;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-count-container-mobile {
  position: absolute;
  right: 80px;
  top: 8px;
  border-radius: 50%;
  background: black;
  color: white;
  font-size: 12px;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-title {
  font-size: 17px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 3px;
}

.bottom-c-mobile {
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  bottom: 0;
  gap: 1rem;
  position: fixed;
  background-color: #000;
  color: gray;
  z-index: 1;
  width: 100%;
}

.bottom-tab {
  cursor: pointer;
  margin-bottom: 0;
}

.tabs-mbl .tab {
  color: #222;
  border: none;
  height: 55px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  background: #f5f5f5;
  letter-spacing: 0.3px;
  font-weight: 500;
}

.tabs-mbl {
  width: 100% !important;
  display: flex;
}

.tabs-mbl .active {
  background: #e9e9e9 !important;
  border-radius: 0 !important;
  border: none !important;
}

.tabs-mbl .active:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;

  margin-top: -1px;
  width: 0;
  height: 2px;
  transition: width 0.25s;
}

.slider-container {
  display: flex;
  flex-direction: column;
  margin-top: -14px;
}

.slider-container-item {
  border-bottom: 1px solid lightgray;
  padding: 10px 20px;
  background: white;
}

.offcanvas-header {
  background: #f5f5f5 !important;
}

.cart-title {
  background: white;
  font-weight: 300 !important;
  text-align: center;
  width: 100%;
}

.cart-header {
  background: white !important;
  border-bottom: 1px solid black;
}

.cart-main-con {
  display: flex;

  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  padding-top: 80px;
}

.cart-main-cont-button {
  background: #b59677;
  color: white;
  outline: none;
  text-decoration: none !important;
  border: none;
  cursor: pointer;
  padding: 5px 25px;
}

.fa-shopping-bag {
  font-size: 50px;
  margin-bottom: 1.4rem;
}

.search-inp {
  outline: none;
  padding: 5px 20px;
  border: 1px solid lightgray;
}

.search-inp::placeholder {
  font-size: 14px;
}

.form-label-login {
  font-weight: 400;
}

.form-label-login span {
  color: red;
}

.input {
  outline: none;
  border: 1px solid lightgray;
  padding: 5px 20px;
}

.input-box {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.login-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sign-in-btn {
  border: none;
  outline: none;
  color: white;
  background: #b59677;
  padding: 10px;
}

.sign-in-btn:hover {
  opacity: 0.8;
}

.model-txt {
  font-size: 14px;
}

.color-brown:hover {
  color: #b59677;
  cursor: pointer;
}

.password-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cart-data-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card-data-card {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  gap: 2rem;
}

.card-data-card:hover {
  background: #f5f5f5;
}

.cart-product-img {
  height: 170px;
  width: 170px;
}

.cart-product-title {
  color: #222;
  font-size: 14px;
  font-weight: 500;
}

.cart-product-title:hover {
  cursor: pointer;
  color: #b59677;
}

.product-cart-description {
  color: #999;
  font-size: 12px;
}

.price-container {
  display: flex;
}

.discounted_price {
  color: #878787;
  text-decoration: line-through;
}

.price-container .price {
  font-size: 15px;
}

.cat-container-left .quantity-container {
  margin-left: 0;
  padding: 10px 60px;
}

.cart-icon-container {
  display: flex;
  gap: 1rem;
  padding: 20px 0;
}

.cart-icon-container i {
  cursor: pointer;
  font-weight: lighter;
}

.cart-icon-container i:hover {
  color: #b59677;
}

.cart-tabs-container {
  background: #f5f5f5;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-tab {
  box-shadow: 9px 5px 10px #dfe3ea, -5px -5px 10px #faffff;
  background: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 16px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}

.fixed-bottom-container {
  background: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 20px;
  transition: opacity 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s,
    transform 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s,
    -webkit-transform 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s;
  transform: translateY(0);
  border-top: 1px solid rgba(129, 129, 129, 0.2);
  box-shadow: 0 0 10px 0 rgba(129, 129, 129, 0.2);
}
.top-fixed-box {
  display: flex;
  justify-content: space-between;
}
.subtotal-heading {
  font-weight: 500;
  font-size: 20px;
}
.fixed-price {
  font-size: 18px;
}
.fixed-text {
  font-size: 12px;
}
.fixed-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
}
.view-cart-btn {
  background: #f7f7f7;
  color: #222;
  border: none;
  font-size: 15px;
  outline: none;
  text-align: center;
  text-decoration: none;
  padding: 10px 0;
}
.view-cart-btn:hover {
  opacity: 0.9;
}

.checkout-btn {
  background: #b59677;
  color: white;
  border: none;
  outline: none;
  font-size: 15px;
  padding: 10px 0;
}
.checkout-btn:hover {
  opacity: 0.8;
}
.card-bottom-title {
  font-size: 15px;
}
.bottom-textarea::placeholder {
  font-size: 14px;
  color: #222;
}
.bottom-textarea {
  outline: none;
  padding: 4px 14px;
}
.cancel-btn {
  background: white;
  padding: 5px 0;
}
.cart-data-container {
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: black;

  scrollbar-width: thin;
  scrollbar-color: black;

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@media only screen and (min-width: 1600px) {
  .navbar-c.scrolled {
    top: 0px !important;
    transform: translateY(0%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
}

.tabs-container Link {
  color: black;
  text-decoration: none;
}
.user-dropdown {
  display: none !important;
  position: absolute;
  top: 90%;
  padding-top: 16px;
  right: 0;
}

.user-dropdown-container {
  padding: 20px 0;
  width: 170px;
  border-radius: 8px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  z-index: 380;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;
}

.user-dropdown:hover {
  display: block !important;
}
#user_img:hover + .user-dropdown {
  display: block !important;
}

.dropdown-item {
  font-weight: 300;
  text-align: start;
  font-size: 14px;
}
.text-start {
  text-align: start;
  padding-left: 28px !important;
  cursor: pointer;
}
.text-start:hover {
  color: cadetblue !important;
}

.bottom-whatsap-btn {
  background: green;
  padding: 10px;
  padding-left: 0;
  padding-right: 14px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  bottom: 20px;
  z-index: 10;
  right: 20px;
  position: fixed;
  border-radius: 100%;
}
