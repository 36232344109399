.product-single-detail-container {
  display: flex;
  width: 100%;
}

.product-single-detail-first {
  width: 50%;
  display: flex;
  gap: 10px;
  padding: 50px 20px;
}

.sider-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.slider-img {
  height: 230px;
  width: 120px;
}

.main-img {
  position: relative;
}

.discout-container {
  background: #f68e56;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 15px;
  position: absolute;
}

.ne-container {
  background: #109533;
  top: 45px;
  right: 10px;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 15px;
  position: absolute;
}

.product-single-detail-second {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 50px 20px;
  gap: 10px;
}

.product-tiel {
  font-weight: 400;
  text-align: start;
  color: black;
  padding: 20px 30px;
}

.pricing-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.price-box {
  display: flex;
  gap: 10px;
}

.line-price {
  font-size: 23px;
  text-decoration: line-through;
  color: #87878b;
}

.price {
  font-size: 23px;
  color: red;
  text-decoration: none;
}

.stock {
  cursor: pointer;
  color: #6abac4;
  font-weight: 200;
}

.shiipinng-text {
  font-size: 10px;
  font-weight: 200;
  text-align: start;
  padding: 0 30px;
}

.color {
  color: #4d595e;
  font-weight: 700;
  text-align: start;
  padding: 0 30px;
}

.color-box {
  display: flex;
  gap: 10px;
  padding: 0 30px;
}

.color-section {
  color: black;
  font-weight: 200;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 20px;
  cursor: pointer;
}

.color-box .active {
  background: black;
  color: white;
}

.add-to-cart-container {
  display: flex;
  gap: 10px;
  padding: 10px 30px;
}

.quantity-container {
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 30px;
  display: flex;
  gap: 20px;
}

.quantity-container span {
  font-weight: 600;
}

.minus-sign:hover {
  color: #b59677;
  cursor: pointer;
}

.add-to-cart-btn {
  background: cadetblue;
  color: white;
  font-weight: 700;
  outline: none;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
}

.heart-container {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
}

.buy-btn {
  background: black;
  color: white;
  font-weight: 400;
  width: 54%;
  padding: 8px;
  border-radius: 5px;
  margin: 0 30px;
}

.buy-btn:hover {
  opacity: 0.9;
}

.whatsapp-div {
  background-color: rgb(45, 183, 66);
  display: flex;
  padding: 10px;
  border-radius: 40px;
  width: 50%;
  margin: 0 30px;
}

.fa-whatsapp {
  color: white;
  font-size: 40px;
  padding-left: 14px;
}

.helpline {
  color: #d5f0d9;
  font-weight: 300;
}

.online {
  color: white;
  font-size: 9px;
  height: 17px;
  padding: 1px;
  border-radius: 5px;
  background: #62c971;
}

.btn-content-top {
  display: flex;
  gap: 4px;
  padding-left: 20px;
}

.btn-content-bottomm {
  color: white;
  font-weight: 400;
  padding-left: 20px;
}

.whatsapp-div:hover {
  cursor: pointer;
  transform: translateY(10);
}

.sold-text {
  font-weight: 300;
  font-size: 15px;
  text-align: start;
  padding: 10px 30px;
}

.tabs-contain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-heading {
  margin-top: 30px;
  font-weight: 500;
  color: black;
  font-size: 20px;
}

.size-table thead th {
  background: black;
  color: white;
  border: 1px solid white;
  font-weight: 700;
}

.size-table tbody tr td {
  background: #e3e3e3;
  color: black;
  border: 1px solid white;
  font-weight: 300;
}

.bold {
  font-weight: 500 !important;
}

.size-table tbody .first td {
  background: #f6f6f6 !important;
}

.size-table {
  width: 70%;
  margin-left: 30px;
}

.info-table {
  border-collapse: collapse;
  margin: 20px 30px;
}

.info-table tr td {
  text-align: start;
  border: 1px solid black;
  padding-left: 30px;
}

.info-table tr .bold {
  font-weight: 300;
}

.tabs-contan .active {
  border-radius: 0 !important;
}

.tabs-contan .tab:hover {
  border-radius: 0 !important;
  border: none;
}

@media only screen and (max-width: 600px) {
  .product-single-detail-container {
    display: flex;
    flex-direction: column;
  }

  .product-single-detail-second {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 50px 20px;
    gap: 10px;
  }

  .size-box {
    flex-direction: column;
  }

  .add-to-cart-container {
    flex-direction: column;
  }

  .add-to-cart-btn {
    background: #b59677;
    color: white;
    font-weight: 400;
    text-align: center !important;
    outline: none;
    font-size: 15px;
    border: none;
    padding: 0px;
    border-radius: 5px;
    width: 200px;
    padding: 9px 25px;
    text-align: start;
    display: flex;
    padding-left: 47px;
  }

  .buy-btn {
    background: black;
    color: white;
    font-weight: 400;
    width: 79%;
    padding: 1px;
    border-radius: 5px;
    margin: 0 30px;
  }

  .whatsapp-div {
    background-color: rgb(45, 183, 66);
    display: flex;
    padding: 10px;
    border-radius: 40px;
    width: 200px;
    margin: 0 30px;
  }

  .sold-text {
    font-weight: 300;
    font-size: 15px;
    text-align: start;
    padding: 0px;
  }

  .tab-content {
    width: 257px;
  }

  .tab-heading {
    margin-top: 30px;
    font-weight: 500;
    width: 263px;
    color: black;
    font-size: 20px;
  }
}

.bottom-b-mobile {
  display: flex;
  justify-content: space-between;
  padding: 7px 10px;
  bottom: 71px;
  margin-top: 10px;
  gap: 1rem;
  position: fixed;
  background: white;
  z-index: 1;
  width: 100%;
  flex-direction: column;
}

.bottom-b-m {
  text-align: center;
  color: #b59677;
  text-decoration: underline;
}

.bottom-b-m-box {
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
}

.quantity-box {
  display: flex;
  gap: 20px;
  border: 1px solid black;
  font-weight: 700;
  padding: 4px 40px;
  border-radius: 3px;
}

.add-cart-btn-mobl {
  font-weight: 500;
  color: white;
  background: #b59677;
  border-radius: 5px;
  padding: 4px 17px;
}

.size-box {
  display: flex;
  gap: 10px;
  padding-left: 40px;
}

.size-section {
  color: black;
  font-weight: 200;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 20px;
  cursor: pointer;
}

.size-box .active {
  background: black;
  color: white;
}

.size {
  padding-left: 40px;
  text-align: start;
}
