.all-collection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 40px;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .all-collection {
    display: flex;
    flex-wrap: wrap;
    padding: 40px;
    justify-content: space-between;
    flex-direction: column;
  }
}
