.top-header {
  background: #5f9ea0;
  color: #ffffff;
  padding: 8px 20px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .top-header {
    background: #3e3e3e;
    color: #ffffff;
    padding: 14px 20px;
    display: flex;
    gap: 7px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .first-section-top-header {
    display: none !important;
  }

  .navbar-c {
    display: flex;
    position: fixed;
    color: #4d5959;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    top: 81px;
    z-index: 1;
    left: 0;
    width: 100%;
    background-color: white;
  }
}

.first-section-top-header {
  display: flex;
  gap: 1rem;
}

.logo {
  width: 230px;
}

.first-section-icon-container {
  border: 1px solid white;
  height: 30px;
  border-radius: 100%;
  width: 30px;
  cursor: pointer;
}

.first-section-icon-container:hover {
  color: blue;
}

.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flag-img {
  height: 20px;
  width: 30px;
}

.end-section-top-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  gap: 4px;
}

.flags-container {
  position: absolute;
  top: 0%;
  display: none;
  z-index: 4;
  padding-top: 32px;
}

.flags-container-content {
  background: #f6f6f8;
  color: black;
  border-radius: 8px;
  width: 100px;
  padding: 10px 0;
}

.flags-container:hover {
  display: block;
}

.end-section-top-header:hover > .flags-container {
  display: block;
}
.flag-box {
  margin-bottom: 4px;
}

.fa-pixel {
  color: white;
}

.first-section-icon-container:hover {
  color: #fff;
  transform: scale(1.1);
}

.first-section-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.first-section-top-header a {
  color: white;
}
