.instagram-image-container {
    position: relative;
    display: inline-block;
    cursor:pointer;
}

.instagram-image-container img {
    display: block;
    width: 270px;
    height: 300px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
}

.instagram-image-container:hover .overlay {
    opacity: 1;
}

.instagram-icon {
    font-size: 36px;
}