.product-top-bar {
    background: #F6F6F8;
    width: 100%;
    padding: 20px 70px;
    display: flex;
    justify-content: space-between;
    margin-top: 120px;
}

.product-top-first {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 200;
}

.product-top-first span {
    cursor: pointer;
}

.product-top-first span:hover {
    color: #C89C88;
}

.product-top-first i {
    font-size: 10px;
}

.product-top-end {
    display: flex;
}

.product-top-end {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.product-top-end i {
    font-size: 15px;
    cursor: pointer;
}