.h-instagram {
  padding: 10px;
  border-bottom: 2px solid rgba(119, 119, 119, 0.17);
  text-align: center; /* Center align the heading */
}
.instagram-slider {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.h-instagram span {
  color: #4d5959;
  padding: 10px;
  font-weight: 300;
  border-bottom: 2px solid #b59677;
}

.descp {
  transition: 0.3s;
  font-style: italic;
  text-align: center; /* Center align the description */
  margin-bottom: 40px; /* Add some space below the description */
}

.instagram-slider-container {
  display: flex;
  justify-content: center; /* Center align the Slider */
}

/* If you want no space between the slider items */
.instagram-slider-container .slick-slide > div {
  margin: 0; /* Remove default margin */
}

.instagram-arrows {
  z-index: 100;
  background-color: cadetblue;
  color: #fff;
  font-size: 24px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.instagram-arrows.slick-prev {
  transform: translate(50%, -50%);
  left: 0;
}
.instagram-arrows.slick-next {
  transform: translate(-50%, -50%);
  right: 0;
}

.instagram-arrows::before {
  display: none;
}
.instagram-arrows:hover {
  background-color: cadetblue;
  color: #fff;
}
