.footer {
  background: #faebd7;
  color: #333;
  padding: 60px 80px;
  display: flex;
}
.footer-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
  font-weight: 300;
  align-items: start;
}
.first-section-top-footer {
  display: flex;
  justify-content: start;
  align-items: start;
}
.first-icon-container {
  height: 35px;
  border-radius: 50%;
  width: 35px;
  font-size: 19px;
  cursor: pointer;
}
.first-icon-container:hover {
  background-color: #333;
  color: #faebd7;
}
.first-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.whatsapp-btn {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: rgb(37, 211, 102);
  color: rgb(255, 255, 255);
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 13px;
  line-height: 0;
  letter-spacing: 0.035em;
  padding: 8px 13px;
  text-decoration: none;
  -webkit-font-smoothing: initial;
  cursor: pointer;
  margin-bottom: 40px;
}
.whatsapp-btn .fa-whatsapp {
  padding-left: 0;
  font-size: 21px;
}
.payment-method {
  height: 40px;
  border-radius: 4px;
  width: 60px;
}
.pament-container {
  display: flex;
  gap: 10px;
}
.accept-text {
  font-weight: 300;
  line-height: 1.42;
  letter-spacing: 2px;
  font-size: 1.2em;
  margin-bottom: 20px;
}
.footer-bottom {
  background: #333;
  padding: 20px 50px;
  color: white;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}
.footer-bottom-link {
  color: #b59677;
}
.footer-tab {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.footer-item {
  cursor: pointer;
}
.footer-item:hover {
  color: #b59677;
}
