.card-product-container {
    overflow: hidden;
    padding: 40px;
}

.heading {
    text-align: start;
    font-weight: 500;
}

.top-section {
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
}

.cart-product-image {
    height: 200px;
    width: 140px;
}

.cart-product-main {
    display: flex;
    gap: 1rem;
}

.shopping-cart-product {
    padding: 40px 0;
    border-bottom: 1px solid lightgray;
}

.cart-product-left-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
}

.cart-product-color-box span {
    font-weight: 500;
    color: #999;
}

.cart-product-color-box {
    color: gray;
    display: flex;
    gap: 6px;
}

.cart-price-box {
    display: flex;
    justify-content: start;
    align-items: center;
}

.product-cart-quantity-container {
    display: flex;
    border: 1px solid black;
    width: 60%;
    justify-content: space-evenly;
    padding: 10px 0;
    font-weight: 700;
}

.product-cart-quantity-container span {
    cursor: pointer;
}

.total-price {
    font-weight: 400;
    color: gray;
    font-size: 14px;
    text-align: start;
}

.column-box {
    display: flex;
    justify-content: start;
    align-items: center;
}

.order-section {
    display: flex;
    justify-content: space-between;
    padding: 30px 40px;
}

.order-first-section {
    width: 40%;
}

.order-heading {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-bottom: 10px;
}

.order-textarea {
    outline: none;
    padding: 5px 15px;
    height: 90px;
    width: 100%;
}

.order-second-section {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.subtotal-box{
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}
.subtotal{
    font-size: 20px;
    font-weight: 500;
}
.subtotal-box span{
    font-size: 20px;
    font-weight: 400;
}
.fixed-text-new{
    text-align: end;
    font-size: 14px;
}
.fixed-checkbox{
    display: flex;
    justify-content: flex-end;
}
.shipping-section{
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 100px 0;
    margin: 70px;
    border:1px solid lightgray;
}
.estimate-shipping{
    color: black;
    position: absolute;
    margin-top:-113px;
    background: white;
    font-size: 24px;
    padding:0 40px;
}
.main-shipping-container{
    display: flex;
    padding: 0 100px;
    gap: 0;
}
.input-container{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.checkout-button{
    background: #B59677;
    color: white;
    border: none;
    height: 35px;
    margin-top: 20px !important;
}
.checkout-button:hover{
    opacity: .8;
}
.input-container .input{
    width: 100%;
    background: white !important;
}

@media only screen and (max-width:400px){
  .card-product-container  .top-section{
        display: none;
    }
    .shopping-cart-product{
        flex-direction: column;
    }
    .product-cart-quantity-container {
        display: flex;
        border: 1px solid black;
        width: 55%;
        justify-content: space-around;
        font-weight: 700;
    }
    .order-section{
        flex-direction: column;
        gap: 18px;
    }
    .order-first-section{
        width: 100%;
    }
    .order-second-section{
        width: 100%;
    }
    .main-shipping-container{
        flex-direction: column;
        padding: 0 10px;
    }
    .checkout-button{
        height: unset;
    }
}
@media only  screen and (min-width:400px ) {
    .shopping-cart-product{
        flex-direction: row !important;
    }
 .shopping-cart-product   .w-60{
        width: 40%;
    }
   .shopping-cart-product  .column-box{
        width: 20%;
       justify-content: center;
    }
   .main-shipping-container{
       display: flex !important;
   }
}