.bg-grey {
  background: #d3d3d3;
  width: 100%;
  height: 230px;
}
.content-containeer {
  width: 100%;
  display: flex;
  gap: 1.5rem;
}
.collction-sidebar {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 320px;
  padding-left: 40px;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  padding-block: 1.5rem;
  border-top-right-radius: 6px;
}

@media only screen and (max-width: 600px) {
  .collction-sidebar {
    display: none;
    width: 25%;
    padding-left: 80px;
    justify-content: center;
  }
}

.collect-title {
  padding-bottom: 20px;
  position: relative;
  font-weight: 500;
  margin-top: 0;
  font-size: 18px;
}

.collect-title:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #222;
  position: absolute;
  left: 0;
  bottom: 16px;
}

.search-for-product {
  border-radius: 5px;
  border: 2px solid cadetblue;
  outline: 0;
  margin-bottom: 12px;
  font-size: 12px;
  padding: 10px 15px;
  transition: border-color 0.5s;
}

.search-for-product::placeholder {
  font-weight: bold;
}

.filter-btn {
  order: 2;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 10px 14px;
  font-size: 12px;
  width: 80px;
  font-weight: 500;
  color: cadetblue;
  background: white;
  line-height: 16px;
  text-transform: uppercase;
  text-shadow: none;
  letter-spacing: 0.3px;
  outline: none;
  border: 1px solid cadetblue;
  min-width: 100px;
}

.collect-price {
  padding-bottom: 20px;
  position: relative;
  font-weight: 500;
  margin-top: 0;
  font-size: 18px;
}

.collect-price:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #222;
  left: 0px;
  bottom: 16px;
  position: absolute;
}

.filter-btn:hover {
  background: cadetblue;
  color: white;
}

.sidebar-collect1 {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}

.price_steps_slider {
  width: 100%;
  margin-top: -1px;
  height: 20px;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  margin-bottom: 20px;
}
.checkboxes-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
}
.checkbox-box {
  display: flex;
  gap: 10px;
}
.ceckbox-val {
  color: black;
}
.ceckbox-quant {
  color: gray;
}
.checkbox {
  color: red;
}
label {
  color: black;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}

.checkbox-radio {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid cadetblue;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
}

.checkbox-radio:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #b59677;
  border-radius: 50%;
}

label:hover {
  color: cadetblue;
}

.AllCollectionHeader {
  display: flex;
  justify-content: space-between;
  padding: 40px;
}
.filter-container {
  color: #878787;
  cursor: pointer;
}

.cat_view .dev_tablet {
  display: none;
}
.grid-container {
  display: flex;
  gap: 1rem;
}
.first-grid-list {
  border: 1px solid black;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  cursor: pointer;
}
.second-grid-list {
  border: 1px solid black;
  padding: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 3px;
  cursor: pointer;
}
.second-grid-item {
  background: #878787;
  height: 21px;
  width: 9px;
}
.first-grid-item {
  background: #878787;
  height: 4px;
  width: 24px;
}
.feture-inp {
  border: 1px solid #ddd;
  border-radius: 40px;
  padding: 6px;
  background: white;
  outline: none;
  font-weight: 300;
}

.tabs-container {
  width: 100%;
}

.tab-buttons {
  display: flex;
}

.tab-buttons button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #f0f0f0;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s ease;
}

.tab-buttons button.active {
  border-bottom-color: #007bff;
  font-weight: bold;
}

.tab-content .tab-panel {
  display: none;
}

.tab-content .tab-panel.active {
  display: block;
}

.grid-container .active .second-grid-item {
  background: #222222;
}
.grid-container .active .first-grid-item {
  background: #222222;
}
.grid-four-tab {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.grid-third-tab {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.grid-two-tab {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
.grid-first-tab {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}
.all-collection-content {
  flex-grow: 1;
  padding-right: 40px;
}
@media only screen and (max-width: 600px) {
  .first-tab-card {
    width: 266px;
    overflow: hidden;
    margin: 20px;
    perspective: 1500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .discount-container-first {
    background: #f68e56;
    top: 36px;
    left: 65px !important;
    color: white;
    font-size: 8px !important;
    font-weight: bold;
    padding: 5px !important;
    position: absolute;
  }
  .new-container-first {
    background: #109533;
    top: 72px;
    left: 65px !important;
    color: white;
    font-size: 8px !important;
    font-weight: bold;
    padding: 5px !important;
    position: absolute;
  }
  .grid-two-tab {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  .grid-container {
    display: flex;
    gap: 1rem;
    margin-top: 65px;
    justify-content: center;
    align-items: center;
    margin-left: 36px;
  }
}
.filter-container-vertical {
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 40px 50px;
  margin: 0 40px;
  border: 1px solid #e7e6e6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 40px;
}

.rc-slider {
  margin-bottom: 1rem;
}

.pagination {
  display: flex !important;
  justify-content: center;
  gap: 8px;
  margin-top: 1.5rem !important;
}

.pagination-button {
  padding: 4px 12px;
  background-color: transparent;
  border: 1px solid cadetblue;
  color: cadetblue;
  font-weight: 500;
  border-radius: 4px;
}

.pagination-button.active,
.pagination-button:hover:not(:disabled) {
  background-color: cadetblue;
  color: #fff;
}

.pagination-button:disabled {
  background-color: lightgray;
  color: gray;
  border-color: lightgray;
}
