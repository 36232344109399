.checkout-container{

}
.checkout-header{
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px  solid lightgray;
    justify-content: space-between;
    padding: 15px 100px;
}
.checkout-header-heading{
    font-weight: bold;
    font-size: 20px;
}
.fa-shopping-bag{
    font-size: 21px;
    margin-bottom: -10px;
    color: cornflowerblue;
}
.checkout-main{
    display: flex;
    width: 100%;
}
.checkout-left{
    width: 50%;
    padding: 40px 50px;
    padding-left: 100px;

}
.checkout-right{
    width: 50%;
    border-left: 1px solid lightgray;
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 40px 10px;
    height: 100vh;
    background: #FAFAFA;
}
.checkout-product-image{
    height: 70px;
    width: 50px ;
}
.checout-product-container{
    display: flex;
    width: 94%;
    padding-left: 30px;
    align-items: center;
    justify-content: space-between;
}
.checkout-product-left{
    display: flex;
    justify-content: center;
    align-items: center;
}
.payment-methods{
    display: flex;
justify-content: space-between;
}
.payment-methods img{
    width: 130px;
    height: 90px;
}
.checkout-product-title{
    text-align: start;
    font-weight: 500;
}
.checkout-product-descrption{
    color: grey;
    font-size: 12px;
}
.checkout-product-img{
    position: relative;
    background: #F1F1F1;
    border:1px solid lightgray;
    border-radius: 9px;
    padding:0 10px;
}
.checkout-quantity{
    position: absolute;
    background: #6E6E6E;
    color: white;
    height: 22px;
    width: 22px;
    border-radius: 100%;
    top:-13px;
    right: -7px;
}
.checkoutproduct-detail{
    padding-left: 10px;
}
.checkout-product-right{
    text-align: center;
    font-size: 14px;
}
.subtotal-container{
    display: flex;
    justify-content: space-between;
    padding:5px 40px;
    font-size: 14px;
}
.total-container{
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
}
.total-container b{
    font-weight: bold;
    font-size: 17px;
}
.pricing-final span{
    font-size: 12px;
    color: grey;
    padding-right: 3px;
}
.pricing-final b{
    font-size: 19px;
}
.checkouts-products-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 92%;
}
.checkout-pricing-container{
    display: flex;
    width: 92%;
    flex-direction: column;
}
.checkout-top-account-section{
    display: flex;
    justify-content: space-between;
}
.checkout-top-account-section{
    color: grey;
    font-weight: 400;
}
.checkout-top-account-section i{
    color: blue;
    background: #F3F8FC;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
}
.email-logout{
    display: flex;
    flex-direction: column;
    font-weight: 400;
    gap: 8px;
    justify-content: start;
    align-items: start;
    font-size: 14px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 20px;
}
.email-logout span{
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}
.delivery-heading{
    font-weight: 600;
    font-size: 1.3rem;
    text-align: start;
    padding:20px 0;
}
.inputs-container-main{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.input-new{
    border: 1px solid lightgray;
    padding: 13px 10px;
    font-size: 14px;
    border-radius: 9px;
    width: 100%;
    outline: none;
}
.input-new:focus{
    border: 2px solid #197BBD;
}
.shipping-container{
    width: 100%;
    border: 1px solid #197BBD;
    background: #F2F7FF;
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    font-weight: 400;
}
.shipping-container p{
    padding-top: 6px;
}
.shipping-container span{
    font-weight:600;
}
.text-small{
    text-align: start;
    color: grey;
    font-size: 14px;
}
.complete-btn{
    background: #197BBD;
    color: white;
    font-weight: bold;
    padding:10px;
    width: 100%;
    margin: 40px 0;
    border:none;
    border-radius: 5px;
}

@media only screen and (max-width: 500px) {
    .checkout-header {
 padding:10px 20px;
    }
    .checkout-header-heading{
        width: 90%;
    }
    .checkout-main{
        flex-direction: column;
    }
    .checkout-right{
        position: unset;
    }
    .checkout-top-account-section{

    }
    .checkout-left{
        width: 100%;
        padding:20px;
    }
    .checkout-right{
        width: 100%;
    }
}